import React from "react"
import Card from "@material-ui/core/Card"
import { css } from "@emotion/core"
import GatsbyImage from "gatsby-image"
import PropTypes from "prop-types"
import { Link } from "gatsby-theme-material-ui"
import { Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"

const BlogShortCard = ({ title, url, date, excerpt, image}) => {
  return (
    <Box my={1}>
      <Card>
        <div css={css`
            text-align: left;
            padding: 1em;
          `}
        >
          <Link to={url} underline={"none"}>
            <div>
              <div css={css`
                  margin: 0.3em 0 0 0;
                `}
              >
                <Typography variant="h2" color={"textPrimary"} gutterBottom={false}>
                  {title}
                </Typography>
              </div>
              <div>
                <Typography variant={"body1"} color={"textSecondary"}>
                  {date}
                </Typography>
              </div>
            </div>
            {image && (
              <div css={css`margin-bottom: 0.5em;`}>
                <GatsbyImage fluid={image}/>
              </div>
            )}
          </Link>
          <div css={css`
              margin: 0;
              p {
                margin-bottom: 0.5em;
              }
              a {
                display: inline;
              }
              `}>

            <p>{excerpt} &nbsp;
              <Link to={url} color="initial">
                weiterlesen
              </Link>
            </p>
          </div>
        </div>
      </Card>
    </Box>
  )
}

BlogShortCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  image: PropTypes.object,
}

export default BlogShortCard
