import React from 'react'
import {graphql} from "gatsby";
import {Container} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import BlogShortCard from "../components/BlogShortCard";
import SEO from "../components/seo";

const Page = ({data}) => {
  const posts = data.allMdx.nodes

  return (
    <React.Fragment>
      <SEO title="Gesellschaft"/>
      <Container maxWidth="md">
        {posts.map(x => {
          return (
            <Box key={x.slug}>
              <BlogShortCard date={x.frontmatter.date}
                             excerpt={x.excerpt}
                             url={"/" + x.slug}
                             title={x.frontmatter.title}
                             image={x.frontmatter.titleImage?.childImageSharp.fluid}
              />
            </Box>)
        })}
      </Container>
    </React.Fragment>
  )
}

export default Page

export const pageQuery = graphql`
    query allBlogPosts {
        allMdx(sort: {order: DESC, fields: [frontmatter___date]}, filter: {slug: {regex: "/posts/"}, frontmatter: {draft: {eq: false}}}) {
            nodes {
                id
                slug
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    title
                    titleImage {
                        childImageSharp {
                            fluid(maxWidth: 882, maxHeight: 200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                excerpt
            }
        }
    }
`
